.main {
    display: inline;
}

.form {
    position: absolute;
    top: 0;
    left: 30%;
    margin-top: 40px;
    margin-left: 40px;
    height: 430px;
    width: 450px;
    background-color: rgb(198, 144, 144);
}

a {
    padding: 20px 20px;
    text-decoration: none;
    font-size: 20px;

}