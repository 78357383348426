video,
button {
  display: block;
  padding: 0;
  margin: 0;
}

.op {
  display: none;
}

.camera {

  position: absolute;
  margin-top: 40px;
  margin-left: 40px;

}

video {
  background: rgb(177, 138, 138);
}

.btn-capture {
  margin-top: 20px;
  margin-left: 70px;
  height: 50px;
  width: 100px;
  border-radius: 2px solid rgb(198, 144, 144);
  background: rgb(198, 144, 144);
}

.view {
  position: absolute;
  margin-top: 500px;
  margin-left: 400px;
  width: 200px;
  height: 50px;
  border-radius: 2px solid rgb(198, 144, 144);
  background: rgb(198, 144, 144);
}

.second {}

.msg {
  position: absolute;
  margin-top: 50px;
  margin-left: 50px;
}

.images {
  position: absolute;
  margin: 80px 80px;
}

img {
  border: none;
  height: 200px;
  width: 200px;
  padding-top: 20px;
  padding-left: 40px;
}